<template>
  <div id="banner-show-page">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>

      <vx-card v-for="locale in locales" :key="locale" :title="locale === 'tm' ? 'Türkmen' : locale === 'ru' ? 'Русский' : 'English'" class="mb-3 " collapse-action>
        <div class="vx-row mb-2">
          <div v-for="size in sizes" :key="size" class="w-1/4">
            <BannerImage :size="size" :locale="locale" :banner="banner" />
          </div>
        </div>
      </vx-card>

      <vx-card class="my-6">
        <vs-row class="mb-3">
          <h3 class="pt-3 truncate">{{banner[`title_${$i18n.locale}`]}}</h3>
          <router-link v-if="$acl.check('admin')" :to="{name: 'bannerEdit', params: {id: $route.params.id}}" class="p-3 mb-4 mr-4 ml-auto rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t('edit') }}</span>
          </router-link>
          <div v-if="$acl.check('admin')" class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteData(banner.uuid)">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base">{{ $t('delete') }}</span>
          </div>
        </vs-row>
        <vs-table stripe :data="Object.keys(banner)">
          <template slot="thead">
            <vs-th>{{ $t('property') }}</vs-th>
            <vs-th>{{ $t('value') }}</vs-th>
          </template>
          <template>
            <vs-tr v-for="(prop, idx) in Object.keys(banner)" :key="idx">
              <template v-if="notImageProp(prop)">
                <vs-td v-if="prop !== 'child'">
                  <span class="uppercase font-medium">{{ $t(`props.${prop}`) }}</span>
                </vs-td>
                <vs-td v-if="banner[prop] === true || banner[prop] === false">
                  <vs-chip :color="banner[prop] ? 'primary' : 'danger'">
                    <feather-icon v-if="banner[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                    <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                  </vs-chip>
                </vs-td>
                <vs-td v-else-if="prop === 'childId' && banner.child">
                  {{ banner['child'][`name_${$i18n.locale}`] }} / ID: {{ banner[prop] }} / UUID: {{ banner.uuid }}
                </vs-td>
                <vs-td v-else-if="prop === 'createdAt' || prop === 'updatedAt'">
                  {{ new Date(banner[prop]).toLocaleString('ru-RU') }}
                </vs-td>
                <vs-td v-else-if="prop !== 'child'">
                  {{ banner[prop] | capitalize }}
                </vs-td>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </template>
  </div>
</template>

<script>
export default {
  components: {
    BannerImage: () => import('./components/BannerImage')
  },
  data () {
    return {
      banner: {},
      isLoading: true,
      sizes: ['slider_image', 'm_slider_image', 'large_image', 'medium_image'],
      locales: ['tm', 'ru', 'en']
    }
  },
  async created () {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/gi
    if (regex.test(this.$route.params.id)) {
      await this.fetchData()
    } else {
      this.$router.push('/error-404')
    }
  },
  methods: {
    async fetchData () {
      await this.$http.get(`/baners/${this.$route.params.id}`)
        .then((response) => { 
          this.banner = {
            ...response.data,
            slider_image_tm: response.data.slider_image_tm ? `${this.$baseUrl}/banners/slider_image_tm/${response.data.uuid}.webp` : null,
            m_slider_image_tm: response.data.m_slider_image_tm ? `${this.$baseUrl}/banners/m_slider_image_tm/${response.data.uuid}.webp` : null,
            large_image_tm: response.data.large_image_tm ? `${this.$baseUrl}/banners/large_image_tm/${response.data.uuid}.webp` : null,
            medium_image_tm: response.data.medium_image_tm ? `${this.$baseUrl}/banners/medium_image_tm/${response.data.uuid}.webp` : null,
            slider_image_ru: response.data.slider_image_ru ? `${this.$baseUrl}/banners/slider_image_ru/${response.data.uuid}.webp` : null,
            m_slider_image_ru: response.data.m_slider_image_ru ? `${this.$baseUrl}/banners/m_slider_image_ru/${response.data.uuid}.webp` : null,
            large_image_ru: response.data.large_image_ru ? `${this.$baseUrl}/banners/large_image_ru/${response.data.uuid}.webp` : null,
            medium_image_ru: response.data.medium_image_ru ? `${this.$baseUrl}/banners/medium_image_ru/${response.data.uuid}.webp` : null,
            slider_image_en: response.data.slider_image_en ? `${this.$baseUrl}/banners/slider_image_en/${response.data.uuid}.webp` : null,
            m_slider_image_en: response.data.m_slider_image_en ? `${this.$baseUrl}/banners/m_slider_image_en/${response.data.uuid}.webp` : null,
            large_image_en: response.data.large_image_en ? `${this.$baseUrl}/banners/large_image_en/${response.data.uuid}.webp` : null,
            medium_image_en: response.data.medium_image_en ? `${this.$baseUrl}/banners/medium_image_en/${response.data.uuid}.webp` : null
          }
          this.isLoading = false
        })
        .catch((error)   => {
          this.isLoading = false
          this.$router.push('/banners')
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    deleteData (id) {
      this.$http.delete(`/baners/delete/${id}`).then(() => {
        this.$vs.notify({
          title: 'Success',
          text: 'Banner successfully deleted!',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
        this.$router.push('/banners')
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    notImageProp (prop) {
      let slug = prop.split('_')
      slug = slug[slug.length - 2]
      return slug !== 'image' && slug !== 'id' && slug !== 'uuid' && slug !== 'createdAt' && slug !== 'updatedAt'
    },
    updateCurrImg (input, field) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.banner[field] = e.target.result
          this.$http.post(`/baners/upload-image/${this.banner.uuid}`, {
            photo: this.banner[field],
            key: field
          }).then(() => {
            this.$vs.notify({
              title: 'Success',
              text: 'Banner image successfully uploaded!',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'primary'
            })
          }).catch(err => {
            this.$vs.notify({
              title: 'Error',
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
            this.banner[field] = null
          })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    async removeCurrImg (field) {
      await this.$http.delete(`/baners/delete-image/${this.banner.uuid}`, {
        data: { key: field }
      }).then(() => {
        this.$vs.notify({
          title: 'Success',
          text: 'Banner image successfully deleted!',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
        this.banner[field] = null
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>